import axios from "axios";
import React, { useEffect, useState } from "react";

const TaskList = () => {
  const [taskList, setTaskList] = useState([]);

  /* API FUNCTION */
  const getTaskList = () => {
    axios
      .get("/api/suggestedTasks")
      .then((res) => {
        console.log(res);
        const result = res?.data?.data;
        console.log(result);
        setTaskList(result);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  /* API FUNCTION end */

  useEffect(() => {
    getTaskList();
  }, []);

  return (
    <div className="main-container">
      {/* Stories */}
      <section className="story-sec">
        <div className="story-box">
          <div className="story-header">
            <div className="story-left">
              <h2 className="comm-hdn">Tasks</h2>
            </div>
            <div className="story-right">
              <div className="form-grp story-search">
                <i className="ph ph-magnifying-glass" />
                <input
                  type="search"
                  className="form-field"
                  id="story"
                  name="story"
                  placeholder="Search"
                  required
                />
              </div>
              <div className="filter-btn-wrap">
                <a href="#" className="button line">
                  <i className="ph ph-sliders" /> Filters
                </a>
                <a href="#" className="button">
                  <i className="ph ph-plus" /> Add New
                </a>
              </div>
            </div>
          </div>
          <div className="task-wrap">
            <ul>
              {taskList?.map((item) => {
                return (
                  <li key={item?.id}>
                    <p>{item?.task_title}</p>
                    <div className="task-btn-wrap">
                      <div className="task-category black">
                        {item?.category_name}
                      </div>
                      <div className="task-group">{item?.age_group}</div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </section>
      {/* Stories Ends */}
    </div>
  );
};

export default TaskList;
