import React from "react";

/* plugin */
import { useNavigate } from "react-router-dom";

const BackButton = () => {
  const navigate = useNavigate();
  return (
    <div
      className="text-indigo-500 inline-flex items-center mb-6 cursor-pointer"
      onClick={() => navigate(-1)}
    >
      <svg
        className="w-4 h-4 mr-1 rotate-180"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth="2"
        fill="none"
        strokeLinejoin="round"
        strokeLinecap="round"
      >
        <path d="M5 12h14"></path>
        <path d="M12 5l7 7-7 7"></path>
      </svg>
      Back
    </div>
  );
};

export default BackButton;
