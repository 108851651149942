import React from "react";

/* plugin */
import axios from "axios";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

/* Component */
import BackButton from "../components/BackButton";
import gs from "../../service/global";

const StoriesPart = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  console.log(id);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
  });

  /* API */
  const onSubmit = (data) => {
    /* Loader Starts */
    gs.showLoader(true);
    /* Loader Ends */

    let payload = {
      story_id: +id,
      content: data?.content,
    };
    console.log(payload);

    axios
      .postForm("api/story-parts", payload)
      .then((res) => {
        console.log(res);

        navigate(`/stories/${id}`, { replace: true });
        /* Loader Starts */
        gs.showLoader(false);
        /* Loader Ends */
      })
      .catch((error) => {
        console.log(error);

        /* Loader Starts */
        gs.showLoader(false);
        /* Loader Ends */
      });
  };
  /* API end */

  return (
    <div className="main-container">
      <section className="story-sec">
        <BackButton />

        <div className="story-box">
          <div className="story-header">
            <div className="story-left">
              <h2 className="comm-hdn">Add Stories Part</h2>
            </div>
          </div>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="grid gap-4 grid-cols-2">
              <div className="form-grp">
                <p className="form-label">Content</p>
                <input
                  className="form-field"
                  type="text"
                  id="content"
                  name="content"
                  {...register("content", {
                    required: "Content is required",
                  })}
                />
                {errors.content && (
                  <p className="error-msg">{errors.content.message}</p>
                )}
              </div>
            </div>

            <button className="button mt-8">Submit</button>
          </form>
        </div>
      </section>
    </div>
  );
};

export default StoriesPart;
