import React from "react";

/* plugin */
import { RouterProvider } from "react-router-dom";

/* Important Imports */
import appRouter from "./routes/routes";
import Interceptor from "./interceptors/Interceptor";
import "../src/assets/css/main.css";
import { AuthContextProvider } from "./context/AuthContext";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

Interceptor();
function App() {
  return (
    <AuthContextProvider>
      <React.StrictMode>
        <RouterProvider router={appRouter} />
        <ToastContainer />
      </React.StrictMode>
    </AuthContextProvider>
  );
}

export default App;
