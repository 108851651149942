import React from "react";

const DeletePopupCmp = (props) => {
  const { closePopup, deleteId } = props;

  return (
    <>
      <svg
        onClick={closePopup}
        className="absolute w-[18px] h-[18px] right-[20px] top-[20px] cursor-pointer"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
        fill="#000"
      >
        <path d="M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z" />
      </svg>
      <h4 className="text-2xl font-semibold text-black mb-2">
        Confirm Deletion
      </h4>
      <p className="mb-10">Are you sure you want to delete?</p>
      <div className="flex gap-4 items-center justify-center ">
        <div className="button red rounded-lg" onClick={deleteId}>
          Yes, delete
        </div>
        <div className="button green rounded-lg" onClick={closePopup}>
          No, don't delete
        </div>
      </div>
    </>
  );
};

export default DeletePopupCmp;
