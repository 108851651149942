import React, { useEffect, useState } from "react";

/* plugin */
import axios from "axios";
import { Link } from "react-router-dom";
import gs from "../../service/global";

/* component */
import StoriesCardCmp from "../components/StoriesCardCmp";

const StoriesList = () => {
  const [stoiresList, setStoiresList] = useState([]);

  /* API FUNCTION */
  const getStoiresList = () => {
    axios
      .get("/api/stories")
      .then((res) => {
        const result = res?.data?.data;
        // console.log(result);
        setStoiresList(result);

        /* Loader Starts */
        gs.showLoader(false);
        /* Loader Ends */
      })
      .catch(function (error) {
        console.log(error);

        /* Loader Starts */
        gs.showLoader(false);
        /* Loader Ends */
      });
  };
  /* API FUNCTION end */

  useEffect(() => {
    getStoiresList();

    /* Loader Starts */
    gs.showLoader(true);
    /* Loader Ends */
  }, []);

  return (
    <div className="main-container">
      <section className="story-sec">
        <div className="story-box">
          <div className="story-header">
            <div className="story-left">
              <h2 className="comm-hdn">Stories</h2>
              {stoiresList ? (
                <h3 className="story-count">
                  <span>{stoiresList?.length} </span>Stories Found
                </h3>
              ) : null}
            </div>
            <div className="story-right">
              {/* <div className="filter-tab active">
                <p>3-6 Years</p>
                <i className="ph ph-x" />
              </div>
              <div className="form-grp story-search">
                <i className="ph ph-magnifying-glass" />
                <input
                  type="search"
                  className="form-field"
                  id="story"
                  name="story"
                  placeholder="Search"
                  required
                />
              </div> */}
              <div className="filter-btn-wrap">
                {/* <a href="#" className="button line">
                  <i className="ph ph-sliders" /> Filters
                </a> */}
                <a href="/add-new-stories" className="button">
                  <i className="ph ph-plus" /> Add New Stories
                </a>
              </div>
            </div>
          </div>

          <div className="story-card-wrap">
            <div className="f-row f-4 gap24 comm-flex">
              {stoiresList?.length > 0 ? (
                stoiresList?.map((item) => (
                  <div className="f-col" key={item?.id}>
                    <Link className="dFlex" to={`/stories/${item?.id}`}>
                      <StoriesCardCmp data={item} />
                    </Link>
                  </div>
                ))
              ) : (
                <div className="f-col">
                  <p>Stoires Loading...</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default StoriesList;
