import React, { useEffect, useState } from "react";
import BackButton from "../components/BackButton";

/* plugin */
import axios from "axios";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

/* Component */
import gs from "../../service/global";

const AddStories = () => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
  });

  /* API */
  const onSubmit = (data) => {
    /* Loader Starts */
    gs.showLoader(true);
    /* Loader Ends */

    let payload = {
      course_id: 4,
      topic: data?.topic,
      short_description: data?.description,
      read_time: data?.reading,
    };
    // console.log(payload);

    axios
      .post("api/stories", payload)
      .then((res) => {
        console.log(res);

        navigate(`/stories`, { replace: true });
        /* Loader Starts */
        gs.showLoader(false);
        /* Loader Ends */
      })
      .catch((error) => {
        console.log(error);

        /* Loader Starts */
        gs.showLoader(false);
        /* Loader Ends */
      });
  };
  /* API end */

  return (
    <div className="main-container">
      <section className="story-sec">
        <BackButton />

        <div className="story-box">
          <div className="story-header">
            <div className="story-left">
              <h2 className="comm-hdn">Add New Stories</h2>
            </div>
          </div>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="grid gap-4 grid-cols-2">
              <div className="form-grp">
                <p className="form-label">Topic</p>
                <input
                  className="form-field"
                  type="text"
                  id="topic"
                  name="topic"
                  {...register("topic", {
                    required: "Topic is required",
                  })}
                />
                {errors.topic && (
                  <p className="error-msg">{errors.topic.message}</p>
                )}
              </div>
              <div className="form-grp">
                <p className="form-label">Short Description</p>
                <input
                  className="form-field"
                  type="text"
                  id="shortdesc"
                  name="shortdesc"
                  {...register("shortdesc", {
                    required: "Short Description is required",
                  })}
                />
                {errors.shortdesc && (
                  <p className="error-msg">{errors.shortdesc.message}</p>
                )}
              </div>
              <div className="form-grp">
                <p className="form-label">Reading Time</p>
                <input
                  className="form-field"
                  type="text"
                  id="reading"
                  name="reading"
                  {...register("reading", {
                    required: "Reading is required",
                  })}
                />
                {errors.reading && (
                  <p className="error-msg">{errors.reading.message}</p>
                )}
              </div>
            </div>
            <button className="button mt-8">Submit</button>
          </form>
        </div>
      </section>
    </div>
  );
};

export default AddStories;
