import axios from "axios";
const { REACT_APP_BASEURL } = process.env;

const Interceptor = () => {
  axios.defaults.baseURL = REACT_APP_BASEURL;
  // console.log(variables.baseURL);

  axios.interceptors.request.use(
    function (config) {
      // Do something before request is sent
      let noAuth = config.headers.noAuth;
      // console.log(noAuth);

      config.headers.Accept = "application/json";

      // config.headers.authorization =
      //   "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiNzkwODhlOTk0NWI0YWVhOGJlNjVmMzY2YmM4YTJlMGQ0ZGZlMGI4NDQxYWFkYmQ2ODIyNWJmZmNkYjlkYmZjZDIzNDgyNjU5NmFjM2UzN2MiLCJpYXQiOjE2ODI1MzExMTQuNDE2NDc3LCJuYmYiOjE2ODI1MzExMTQuNDE2NDc5LCJleHAiOjE2OTgzNDIzMTQuMzExNzg4LCJzdWIiOiIxIiwic2NvcGVzIjpbImlzX3N1cGVyYWRtaW4iXX0.SsDiaSon0pnWUCfxNTa46kw3GTN4REJPlGJlFo9zt7Qb0Y0oigXxK2goaAs1sc1rQkU7gFd2yq9FREpUs2tkzpQmCXnylF3ESeFK_tvXCDZDZq2BPzmg13JRaGb5udJKwwGMmD-upz0cReVO_IXpcXctDZ8hixyHOOZG-4E1hSleV62eduRw0VL4wfSlYaj406cXbqotqYzgJnv74bIUkDT3Q89wc8UvVWrsPsGDoBMR-y1Pj9nX8lCmRoKqfSmEKrQyY1r8Uiin5dxOBgy4nIMu48LdBEnbFgUpVnIaFXVswqAnNk_g1Hg9ErZSj8fu2EjHAeIk9EPJY7jMSTR65U4US5TbO52LjEmDb4ReQy2_zswg9xgh3jjYqyGbc_y1K9zIs2fd5jYqU7Yyt7W5jrKV8C1vNvHt15meEQ7_0cM13FV5yRVcWeJp0jQJqn585orfGbUVKgdLDKaS9Z8RyRne4kXFRnpwnbShwjCmI6L1h9_QCK7ioz3iGog0YOg9PMFZAJ-x1V8IK19m28YDzxepz7fTuYeT8icBjwRck6kx03ye8ulx0hnyRemYKktiHfzN_1Uq-YMBXhMjxhdNiCQhdGshTyFAE7pXDiu9P6y9d4_oPGLnAv_543ZJikTOPaUjO8Na-PDYYZI46F7WkDwVlQA8DWbP10qc8xSDQ9A";

      if (!noAuth) {
        // let accessToken = 'AUTH TOKEN 123';
        let accessToken = localStorage.getItem("token");
        if (accessToken) {
          config.headers.authorization = "Bearer " + accessToken;
        } else {
          // console.log('No authentification... Please redirect!');
          // console.log("No authentification!");
        }
      }

      return config;
    },
    function (error) {
      // Do something with request error
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export default Interceptor;
