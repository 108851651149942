import React from "react";
import { createBrowserRouter, Outlet } from "react-router-dom";

/* Common Components */
import Header from "../pages/components/Header";

/* Auth Module */
import AuthLayout from "../pages/auth/AuthLayout";
import Login from "../pages/auth/Login";

import Dashboard from "../pages/Dashboard";

/* stories module */
import StoriesList from "../pages/stories/StoriesList";
import AddStories from "../pages/stories/AddStories";
import AddStoriesUpdate from "../pages/stories/AddStoriesUpdate";
import StoriesPart from "../pages/stories/StoriesPart";
import StoriesPartUpdate from "../pages/stories/StoriesPartUpdate";
import StoriesDetail from "../pages/stories/StoriesDetail";

import Users from "../pages/users/Users";

import AddCourses from "../pages/courses/AddCourses";
import UpdateCoursesPart from "../pages/courses/UpdateCoursesPart";
import CoursesDetail from "../pages/courses/CoursesDetail";

import ErrorPage from "../pages/components/ErrorPage";
import TaskList from "../pages/tasks/TaskList";
import CoursesList from "../pages/courses/CoursesList";
import QuizList from "../pages/quiz/QuizList";
import PrivateRoutes from "../service/PrivateRoutes";

const AppLayout = () => {
  return (
    <PrivateRoutes>
      <Outlet />
    </PrivateRoutes>
  );
};

const appRouter = createBrowserRouter([
  {
    path: "/",
    element: <AuthLayout />,
    children: [
      {
        path: "/",
        element: <Login />,
      },
    ],
  },
  {
    path: "/",
    element: <AppLayout />,
    children: [
      {
        path: "/dashboard",
        element: <Dashboard />,
      },
      {
        path: "/stories",
        element: <StoriesList />,
      },
      {
        path: "/add-new-stories",
        element: <AddStories />,
      },
      {
        path: "/update-stories/:singleStoriesId",
        element: <AddStoriesUpdate />,
      },
      {
        path: "/stories/:storiesId",
        element: <StoriesDetail />,
      },
      {
        path: "/stories-part/:id",
        element: <StoriesPart />,
      },
      {
        path: "/stories-part-update/:id",
        element: <StoriesPartUpdate />,
      },
      {
        path: "/users",
        element: <Users />,
      },
      {
        path: "/tasks",
        element: <TaskList />,
      },
      {
        path: "/courses",
        element: <CoursesList />,
      },
      {
        path: "/add-new-courses",
        element: <AddCourses />,
      },
      {
        path: "/courses/:coursesId",
        element: <CoursesDetail />,
      },
      {
        path: "/update-courses-part/:id",
        element: <UpdateCoursesPart />,
      },
      {
        path: "/quiz",
        element: <QuizList />,
      },
    ],
  },
  {
    path: "*",
    element: <ErrorPage />,
  },
]);

export default appRouter;
