import React from "react";
import Lottie from "lottie-react";
import animationData from "../../assets/json/bhulkoo-loader.json";

// const { REACT_APP_ASSET_URL } = process.env;

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  width: 500,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const Loader = () => {
  return (
    <div className="loaderBig">
      <div className="loaderWidth">
        <Lottie animationData={animationData} options={defaultOptions} />
        {/* <img src={`${REACT_APP_ASSET_URL}/img/logo.svg`} alt="" /> */}
      </div>
    </div>
  );
};

export default Loader;
