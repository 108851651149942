import React, { useEffect, useState } from "react";

/* Plugin */
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import gs from "../../service/global";
import Popup from "reactjs-popup";

/* Componenet */
import BackButton from "../components/BackButton";
import ChangeContentBtn from "../components/ChangeContentBtn";
import DeletePopupCmp from "../components/DeletePopupCmp";

const StoriesDetail = () => {
  const { storiesId } = useParams();
  // console.log(storiesId);
  const navigate = useNavigate();

  const [deleteId, setDeleteId] = useState({});

  const [openNewEntity, setOpenNewEntity] = useState(false);
  const closeNewEntity = () => setOpenNewEntity(false);
  /* API */
  /* Get stories */
  const [singleStoires, setSingleStoires] = useState({});
  const [singleStoiresPart, setSingleStoiresPart] = useState([]);

  const getSingleStoires = (id) => {
    axios
      .get(`/api/stories/${id}`)
      .then((res) => {
        const result = res?.data?.data;
        console.log(result);
        setSingleStoires(result);
        setSingleStoiresPart(result?.story_parts);

        /* Loader Starts */
        gs.showLoader(false);
        /* Loader Ends */
      })
      .catch(function (error) {
        console.log(error);

        /* Loader Starts */
        gs.showLoader(false);
        /* Loader Ends */
      });
  };
  /* Get stories end */

  /* Delete stories */
  const deleteStoriesFun = () => {
    // console.log(deleteId?.name);

    /* Loader Starts */
    gs.showLoader(true);
    /* Loader Ends */

    if (deleteId?.name === "stories") {
      axios
        .delete(`/api/stories/${deleteId?.id}`)
        .then((res) => {
          navigate(`/stories`, { replace: true });
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      axios
        .delete(`/api/story-parts/${deleteId?.id}`)
        .then((res) => {
          closeNewEntity();
          setSingleStoiresPart((prevItems) =>
            prevItems.filter((i) => i.id !== deleteId?.id)
          );
          /* Loader Starts */
          gs.showLoader(false);
          /* Loader Ends */
        })
        .catch(function (error) {
          console.log(error);
          /* Loader Starts */
          gs.showLoader(false);
          /* Loader Ends */
        });
    }
  };
  /* Delete stories end */

  /* API end */

  useEffect(() => {
    getSingleStoires(storiesId);

    /* Loader Starts */
    gs.showLoader(true);
    /* Loader Ends */
  }, [storiesId]);

  useEffect(() => {}, [singleStoiresPart]);

  return (
    <div className="main-container">
      <section className="story-sec">
        <BackButton />

        <div className="mb-14">
          <div className="mb-12">
            <div className="flex justify-between">
              <div className="flex-1 pr-5">
                <h2 className="text-3xl font-medium text-gray-900 title-font mb-2">
                  {singleStoires?.topic}
                </h2>
                <p className="leading-relaxed">
                  {singleStoires?.short_description}
                </p>
                <ChangeContentBtn
                  routeLink={`/update-stories/${singleStoires?.id}`}
                  text="Change Content"
                />
              </div>

              <div
                onClick={() => {
                  setOpenNewEntity((o) => !o);
                  setDeleteId({ name: "stories", id: storiesId });
                }}
                className="button red"
              >
                Delete
              </div>
            </div>
          </div>

          <h2 className="text-2xl font-medium text-gray-900 title-font mb-8">
            Stories Parts
          </h2>
          {singleStoiresPart?.length > 0 ? (
            singleStoiresPart?.map((item) => (
              <div
                className="flex gap-6 mb-5 items-center border-b-2 pb-6"
                key={item?.id}
              >
                <div className="md:flex-grow">
                  <img src={item?.picture} alt="" />
                  {/* <h2 className="text-xl font-medium text-gray-900 title-font mb-2">
                      Bitters hashtag waistcoat fashion axe chia unicorn
                    </h2> */}
                  <p className="leading-relaxed">{item?.content}</p>
                  <ChangeContentBtn
                    routeLink={`/stories-part-update/${item?.id}`}
                    text="Change Content"
                  />
                </div>
                <div
                  onClick={() => {
                    setOpenNewEntity((o) => !o);
                    setDeleteId({ name: "storiespart", id: item?.id });
                  }}
                  className="button red"
                >
                  Delete
                </div>
              </div>
            ))
          ) : (
            <p>No stories parts found.</p>
          )}
        </div>
        <Link to={`/stories-part/${storiesId}`} className="button">
          Add Stories Part
        </Link>
      </section>

      {/* Delete Popup Starts */}
      <Popup open={openNewEntity} closeOnDocumentClick onClose={closeNewEntity}>
        <DeletePopupCmp
          closePopup={closeNewEntity}
          deleteId={deleteStoriesFun}
        />
      </Popup>
      {/* Delete Popup Ends */}
    </div>
  );
};

export default StoriesDetail;
