import React, { useEffect, useState } from "react";

/* Plugin */
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import gs from "../../service/global";
import Popup from "reactjs-popup";

/* Componenet */
import BackButton from "../components/BackButton";
import ChangeContentBtn from "../components/ChangeContentBtn";
import DeletePopupCmp from "../components/DeletePopupCmp";

const CoursesDetail = () => {
    const { coursesId } = useParams();
    // console.log(coursesId);
    const navigate = useNavigate();

    const [deleteId, setDeleteId] = useState({});

    const [openNewEntity, setOpenNewEntity] = useState(false);
    const closeNewEntity = () => setOpenNewEntity(false);
    /* API */
    /* Get courses */
    const [singleCourses, setSingleCourses] = useState({});

    const getSingleCourses = (id) => {
        axios
            .get(`/api/courses/${id}`)
            .then((res) => {
                const result = res?.data?.data;
                console.log(result);
                setSingleCourses(result);

                /* Loader Starts */
                gs.showLoader(false);
                /* Loader Ends */
            })
            .catch(function (error) {
                console.log(error);

                /* Loader Starts */
                gs.showLoader(false);
                /* Loader Ends */
            });
    };
    /* Get courses end */

    /* Delete courses */
    const deleteCoursesFun = () => {
        console.log(deleteId?.name);

        if (deleteId?.name === "courses") {
            axios
                .delete(`/api/courses/${deleteId?.id}`)
                .then((res) => {
                    navigate(`/courses`, { replace: true });
                })
                .catch(function (error) {
                    console.log(error);
                });
        } else {
            axios
                .delete(`/api/update-courses-part/${deleteId?.id}`)
                .then((res) => {
                    window.reload();
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    };
    /* Delete courses end */

    /* API end */

    useEffect(() => {
        getSingleCourses(coursesId);

        /* Loader Starts */
        gs.showLoader(true);
        /* Loader Ends */
    }, [coursesId]);

    return (
        <div className="main-container">
            <section className="story-sec">
                <BackButton />

                <div className="mb-14">
                    <div className="mb-12">
                        <div className="flex justify-between">
                            <div className="flex-1 pr-5">
                                <h2 className="text-3xl font-medium text-gray-900 title-font mb-2">
                                    {singleCourses?.topic}
                                </h2>
                                {/* <p className="leading-relaxed">
                                    {singleCourses?.short_description}
                                </p> */}
                                <ChangeContentBtn
                                    routeLink={`/update-courses-part/${singleCourses?.story_id}`}
                                    text="Change Content"
                                />
                            </div>

                            <div
                                onClick={() => {
                                    setOpenNewEntity((o) => !o);
                                    setDeleteId({ name: "courses", id: coursesId });
                                }}
                                className="button red"
                            >
                                Delete
                            </div>
                        </div>
                    </div>

                    {/* <h2 className="text-2xl font-medium text-gray-900 title-font mb-8">
                        Courses Parts
                    </h2> */}
                    {singleCourses?.story_parts?.length > 0
                        ? singleCourses?.story_parts?.map((item) => (
                            <div
                                className="flex gap-6 mb-5 items-center border-b-2 pb-6"
                                key={item?.id}
                            >
                                <div className="md:flex-grow">
                                    <img src={item?.picture} alt="" />
                                    {/* <h2 className="text-xl font-medium text-gray-900 title-font mb-2">
                      Bitters hashtag waistcoat fashion axe chia unicorn
                    </h2> */}
                                    <p className="leading-relaxed">{item?.content}</p>
                                    <ChangeContentBtn
                                        routeLink={`/update-courses-part/${item?.story_id}`}
                                        text="Change Content"
                                    />
                                </div>
                                <div
                                    onClick={() => {
                                        setOpenNewEntity((o) => !o);
                                        setDeleteId({ name: "update-courses-part", id: item?.id });
                                    }}
                                    className="button red"
                                >
                                    Delete
                                </div>
                            </div>
                        ))
                        : null}
                </div>
                {/* <Link to={`/update-courses-part/${coursesId}`} className="button">
                    Add Courses Part
                </Link> */}
            </section>

            {/* Delete Popup Starts */}
            <Popup open={openNewEntity} closeOnDocumentClick onClose={closeNewEntity}>
                <DeletePopupCmp
                    closePopup={closeNewEntity}
                    deleteId={deleteCoursesFun}
                />
            </Popup>
            {/* Delete Popup Ends */}
        </div>
    );
};

export default CoursesDetail;
