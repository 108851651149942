import React, { useEffect, useState } from "react";
// import Pagination from "@mui/material/Pagination";
/* plugin */
import axios from "axios";
import { Link } from "react-router-dom";
import gs from "../../service/global";

/* component */
import CourseCardCmp from "../components/CourseCardCmp";

const CoursesList = () => {
  const [coursesList, setCoursesList] = useState([]);

  /* API FUNCTION */
  const getCoursesList = () => {
    axios
      .get("/api/courses")
      .then((res) => {
        const result = res?.data?.data;
        setCoursesList(result);

        /* Loader Starts */
        gs.showLoader(false);
        /* Loader Ends */
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  /* API FUNCTION end */

  useEffect(() => {
    getCoursesList();

    /* Loader Starts */
    gs.showLoader(true);
    /* Loader Ends */
  }, []);

  return (
    <div className="main-container">
      <section className="story-sec">
        <div className="story-box">
          <div className="story-header">
            <div className="story-left">
              <h2 className="comm-hdn">Courses</h2>
              {coursesList ? (
                <h3 className="story-count">
                  <span>{coursesList?.length} </span>Courses Found
                </h3>
              ) : null}
            </div>
            <div className="story-right">
              {/* <div className="filter-tab active">
                <p>3-6 Years</p>
                <i className="ph ph-x" />
              </div> 
              <div className="form-grp story-search">
                <i className="ph ph-magnifying-glass" />
                <input
                  type="search"
                  className="form-field"
                  id="story"
                  name="story"
                  placeholder="Search"
                  required
                />
              </div>*/}
              <div className="filter-btn-wrap">
                {/* <a href="#" className="button line">
                  <i className="ph ph-sliders" /> Filters
                </a> */}
                <a href="/add-new-courses" className="button">
                  <i className="ph ph-plus" /> Add New Course
                </a>
              </div>
            </div>
          </div>
          <div className="story-card-wrap">
            <div className="f-row f-4 gap24">
              {coursesList?.length > 0 ? (
                coursesList?.map((item) => (
                  <div className="f-col" key={item?.id}>
                    <Link to={`/courses/${item?.id}`}>
                      <CourseCardCmp data={item} />
                    </Link>
                  </div>
                ))
              ) : (
                <div className="f-col">
                  <p>Courses Loading...</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CoursesList;
