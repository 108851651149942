import { createContext, useState } from "react";

const AuthContext = createContext({
  adminToken: null,
  userInfo: null,
  loginAdmin: () => {},
  logoutAdmin: () => {},
});

export default AuthContext;

export const AuthContextProvider = (props) => {
  const token = localStorage.getItem("token");

  const [adminToken, setAdminToken] = useState(token);

  const loginHandler = (id) => {
    setAdminToken(id);
    localStorage.setItem("token", id);
  };

  /* logout */
  const logoutHandler = async () => {
    localStorage.clear();
  };
  /* logout end */

  const contextValue = {
    adminToken: adminToken,
    loginAdmin: loginHandler,
    logoutAdmin: logoutHandler,
  };
  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};
