import React from "react";

/* plugin */
import axios from "axios";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

/* Component */
import BackButton from "../components/BackButton";
import gs from "../../service/global";

const UpdateCoursesPart = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    console.log(id);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        mode: "all",
    });

    /* API */
    const onSubmit = (data) => {
        /* Loader Starts */
        gs.showLoader(true);
        /* Loader Ends */

        let payload = {
            story_id: +id,
            content: data?.content,
        };
        console.log(payload);

        axios
            .postForm("api/update-courses-part", payload)
            .then((res) => {
                console.log(res);

                navigate(`/courses/${id}`, { replace: true });
                /* Loader Starts */
                gs.showLoader(false);
                /* Loader Ends */
            })
            .catch((error) => {
                console.log(error);

                /* Loader Starts */
                gs.showLoader(false);
                /* Loader Ends */
            });
    };
    /* API end */

    return (
        <div className="main-container">
            <section className="story-sec">
                <BackButton />

                <div className="story-box">
                    <div className="story-header">
                        <div className="story-left">
                            <h2 className="comm-hdn">Upadte courses Part</h2>
                        </div>
                    </div>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="grid gap-4 grid-cols-2">
                            <div className="form-grp">
                                <p className="form-label">Topic</p>
                                <input
                                    className="form-field"
                                    type="text"
                                    id="topic"
                                    name="topic"
                                    {...register("topic", {
                                        required: "Topic is required",
                                    })}
                                />
                            </div>
                            <div className="form-grp">
                                <p className="form-label">Display As</p>

                                <input
                                    className="form-field"
                                    type="text"
                                    id="display-as"
                                    name="display-as"
                                    {...register("Display as", {
                                        required: "display description is required",
                                    })}
                                />
                            </div>
                            <div className="form-grp">
                                <p className="form-label">Description</p>

                                <input
                                    className="form-field"
                                    type="text"
                                    id="description"
                                    name="description"
                                    {...register("Description", {
                                        required: "Description is required",
                                    })}
                                />
                            </div>
                            <div className="form-grp">
                                <p className="form-label">Base amount</p>

                                <input
                                    className="form-field"
                                    type="text"
                                    id="base-amount"
                                    name="base-amount"
                                    {...register("Base amount", {
                                        required: "base amount is required",
                                    })}
                                />
                            </div>
                            <div className="form-grp">
                                <p className="form-label">Discount amount</p>

                                <input
                                    className="form-field"
                                    type="text"
                                    id="discount-amount"
                                    name="discount-amount"
                                    {...register("Discount amount", {
                                        required: "Discount amount is required",
                                    })}
                                />
                            </div>
                            <div className="form-grp">
                                <p className="form-label">Final amount</p>

                                <input
                                    className="form-field"
                                    type="text"
                                    id="final-amount"
                                    name="final-amount"
                                    {...register("Final amount", {
                                        required: "Final amount is required",
                                    })}
                                />
                            </div>
                            <div className="form-grp">
                                <p className="form-label">Age group</p>

                                <input
                                    className="form-field"
                                    type="text"
                                    id="age-group"
                                    name="age-group"
                                    {...register("Age group", {
                                        required: "Age group is required",
                                    })}
                                />
                            </div>
                            {errors.content && (
                                <p className="error-msg">{errors.content.message}</p>
                            )}
                        </div>

                        <button className="button mt-8">Submit</button>
                    </form>
                </div>
            </section>
        </div>
    );
};

export default UpdateCoursesPart;
