import React from "react";

const { REACT_APP_ASSET_URL } = process.env;

const StoriesCardCmp = (props) => {
  const { picture, topic, created_at } = props?.data;

  return (
    <div className="story-card">
      <div className="story-img">
        <img
          src={picture ? picture : `${REACT_APP_ASSET_URL}/img/login-img.jpg`}
          alt=""
          width="100%"
          height="100%"
          loading="lazy"
        />
      </div>
      <div className="stody-info">
        <h3 className="story-hdn">{topic}</h3>
        {/* <div className="category">
          <p>3-6 Yrs</p>
          <p>Values</p>
        </div> */}
        <div className="date">
          <div className="calendar-img">
            <img
              src={`${REACT_APP_ASSET_URL}/img/icon/calendar.svg`}
              alt=""
              width="100%"
              height="100%"
              loading="lazy"
            />
          </div>
          <p>{created_at}</p>
        </div>
      </div>
    </div>
  );
};

export default StoriesCardCmp;
