import React from "react";

const { REACT_APP_ASSET_URL } = process.env;

const UserCardCmp = (props) => {
  const { name, email, profile_photo_path, user_type } = props?.data;

  return (
    <div className="story-card">
      <div className="story-img">
        <img
          src={
            profile_photo_path
              ? profile_photo_path
              : `${REACT_APP_ASSET_URL}/img/login-img.jpg`
          }
          alt=""
          width="100%"
          height="100%"
          loading="lazy"
        />
      </div>
      <div className="stody-info">
        <h3 className="story-hdn">{name}</h3>
        <div className="category">
          <p>{email}</p>
          <p>Values</p>
        </div>
        <div className="user-type">
          <p>
            User Type : <span>{user_type}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default UserCardCmp;
