import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import Header from "../pages/components/Header";

const PrivateRoutes = () => {
  const authCtx = useContext(AuthContext);
  // console.log(!!authCtx?.adminToken);

  return !!authCtx?.adminToken ? (
    <>
      <Header />
      <Outlet />
    </>
  ) : (
    <Navigate to="/" />
  );
};

export default PrivateRoutes;
