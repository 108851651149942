import React from "react";

/* plugin */
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";

const { REACT_APP_ASSET_URL } = process.env;

const Header = () => {
  return (
    <div className="header-box">
      <div className="header-wrap">
        <div className="head-left">
          <div className="logoBox">
            <Link className="logo" to="/dashboard">
              <img src={`${REACT_APP_ASSET_URL}/img/logo.svg`} alt="Logo" />
            </Link>
          </div>
        </div>
        <div className="head-right">
          <ul className="header-list">
            <li>
              <NavLink to="/dashboard">Dashboard</NavLink>
            </li>
            <li>
              <NavLink to="/stories">Stories</NavLink>
            </li>
            <li>
              <NavLink to="/tasks">Tasks</NavLink>
            </li>
            <li>
              <NavLink to="/tips">Tips</NavLink>
            </li>
            <li>
              <NavLink to="/courses">Courses</NavLink>
            </li>
            <li>
              <NavLink to="/users">Users</NavLink>
            </li>
            <li>
              <NavLink to="">Reports</NavLink>
            </li>
            <li className="drop admin-drop">
              <div className="admin-img">
                <img
                  src={`${REACT_APP_ASSET_URL}/img/admin.jpg`}
                  alt="logo"
                  width="100%"
                  height="100%"
                  loading="lazy"
                />
              </div>
              <p>Admin</p>
              <ul>
                <li>
                  <NavLink to="">Profile</NavLink>
                </li>
                <li>
                  <NavLink to="">Dashboard</NavLink>
                </li>
                <li>
                  <p>Logout</p>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Header;
