import React, { useContext, useEffect, useState } from "react";

/* plugin */
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import axios from "axios";

/* Component */
import { toasterConfig } from "../../config/Contant";
import AuthContext from "../../context/AuthContext";

const { REACT_APP_ASSET_URL } = process.env;

const Login = () => {
  const authCtx = useContext(AuthContext);
  // console.log(authCtx);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [showPassword, setShowPassword] = useState(false);

  const onSubmit = (data) => {
    let payload = data;
    // console.log(payload);
    axios
      .post("api/login", payload)
      .then((res) => {
        const result = res?.data;
        const successMessage = result?.message;
        const token = result?.data?.token;
        authCtx.loginAdmin(token);
        // console.log(token);

        toast.success(successMessage, toasterConfig);

        // navigate(`/dashboard`, { replace: true });
        navigate(`/stories`, { replace: true });
      })
      .catch((error) => {
        console.log(error?.response?.data);
        const errorMessage = error?.response?.data?.message;

        toast.error(errorMessage, toasterConfig);
      });
  };

  const passwordHandler = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {}, []);

  return (
    <div className="login-info">
      {/* <div className="logo-box">
        <img
          src={`${REACT_APP_ASSET_URL}/img/logo.svg`}
          alt="login banner"
          width="100%"
          height="100%"
          loading="lazy"
        />
      </div> */}
      <h3 className="login-hdn">Login to admin</h3>
      <div className="login-form">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-grp">
            <p className="form-label">Email</p>
            <input
              className="form-field"
              type="text"
              id="login-id"
              name="login-id"
              defaultValue={"admin@admin.co"}
              {...register("email", {
                required: "Email is required",
                // pattern: {
                //   value: /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/,
                //   message: "Enter a valid Email",
                // },
              })}
            />
            {errors.email && (
              <p className="error-msg">{errors.email.message}</p>
            )}
          </div>
          <div className="form-grp">
            <p className="form-label">Password</p>
            <input
              className="form-field"
              type={!showPassword ? "password" : "text"}
              id="info-password"
              name="info-password"
              defaultValue={"admin"}
              {...register("password", {
                required: "Password is required",
              })}
            />
            {errors.password && (
              <p className="error-msg">{errors.password.message}</p>
            )}
            <i
              className={`view-pass ${
                !showPassword ? "ph-eye-slash" : "ph-eye"
              }`}
              onClick={() => passwordHandler()}
            />
          </div>
          <button className="button">Login</button>
        </form>
      </div>
    </div>
  );
};

export default Login;
