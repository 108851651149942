import React from "react";

const { REACT_APP_ASSET_URL } = process.env;

const CourseCardCmp = (props) => {
  const { picture, category_name, age_group, created_at } = props?.data;

  return (
    <div className="story-card">
      <div className="story-img">
        <img
          src={picture ? picture : `${REACT_APP_ASSET_URL}/img/login-img.jpg`}
          alt=""
          width="100%"
          height="100%"
          loading="lazy"
        />
      </div>
      <div className="stody-info">
        <h3 className="story-hdn">{category_name}</h3>
        <div className="course-card-content">
          {age_group ? (
            <div className="category">
              <p>{age_group} Yrs</p>
            </div>
          ) : null}
          <div className="date">
            <div className="calendar-img">
              <img
                src={`${REACT_APP_ASSET_URL}/img/icon/calendar.svg`}
                alt=""
                width="100%"
                height="100%"
                loading="lazy"
              />
            </div>
            <p>{created_at}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseCardCmp;
