import React, { useEffect, useState } from "react";

/* plugin */
import axios from "axios";
import { Link } from "react-router-dom";
import gs from "../../service/global";

/* component */
import UserCardCmp from "../components/UserCardCmp";

const Users = () => {
  const [userList, setUserList] = useState([]);

  /* API FUNCTION */
  const getUserList = () => {
    axios
      .get("/api/users")
      .then((res) => {
        const result = res?.data?.data;
        // console.log(result);
        setUserList(result);

        /* Loader Starts */
        gs.showLoader(false);
        /* Loader Ends */
      })
      .catch(function (error) {
        console.log(error);

        /* Loader Starts */
        gs.showLoader(false);
        /* Loader Ends */
      });
  };
  /* API FUNCTION end */

  useEffect(() => {
    getUserList();

    /* Loader Starts */
    gs.showLoader(true);
    /* Loader Ends */
  }, []);

  return (
    <div className="main-container">
      <section className="story-sec">
        <div className="story-box">
          <div className="story-header">
            <div className="story-left">
              <h2 className="comm-hdn">Users</h2>
              {userList ? (
                <h3 className="story-count">
                  <span>{userList?.length} </span>Users Found
                </h3>
              ) : null}
            </div>
          </div>
          <div className="story-card-wrap">
            <div className="f-row f-4 gap24 comm-flex">
              {userList?.length > 0 ? (
                userList?.map((item) => (
                  <div className="f-col" key={item?.id}>
                    {/* start */}
                    <Link className="dFlex" to={``}>
                      <UserCardCmp data={item} />
                    </Link>
                    {/* end */}
                  </div>
                ))
              ) : (
                <div className="f-col">
                  <p>Stoires Loading...</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Users;
