import React from "react";
import { Outlet } from "react-router-dom";
const { REACT_APP_ASSET_URL } = process.env;

const AuthLayout = () => {
  return (
    <section className="login-sec">
      <div className="login-wrap">
        <div className="login-img">
          <img
            src={`${REACT_APP_ASSET_URL}/img/login-img.jpg`}
            alt="logo"
            width="100%"
            height="100%"
            loading="lazy"
          />
        </div>
        <Outlet />
      </div>
    </section>
  );
};

export default AuthLayout;
