import React from "react";

/* plugin */
import { Link } from "react-router-dom";

const ChangeContentBtn = (props) => {
  const { routeLink, text } = props;

  return (
    <Link
      to={routeLink}
      className="text-indigo-500 inline-flex items-center mt-4"
    >
      {text}
      <svg
        className="w-4 h-4 ml-2"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth="2"
        fill="none"
        strokeLinejoin="round"
        strokeLinecap="round"
      >
        <path d="M5 12h14"></path>
        <path d="M12 5l7 7-7 7"></path>
      </svg>
    </Link>
  );
};

export default ChangeContentBtn;
